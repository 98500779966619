@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* Boostrap customization */

	// Body
	$body-bg: #f8fafc;

	// Typography
	$font-family-sans-serif: 'Open Sans', sans-serif;
	$font-size-base: 0.9rem;
	$line-height-base: 1.6;

	// Colors
	$blue: #00A7DF;
	$indigo: #6574cd;
	$purple: #9561e2;
	$pink: #f66d9b;
	// $red: #e3342f;
	$red: #ff5550;
	$orange: #f6993f;
	$yellow: #ffed4a;
	$green: #38c172;
	$teal: #4dc0b5;
	$cyan: #6cb2eb;

	// Import bootstrap
	@import '~bootstrap/scss/bootstrap.scss';

/* Custom colors */
:root {
	--bs-blue-light: #00a7df9c;
}





/**
 * Generals
 */
 body, 
 html {
	 height: 100%;
 }
 
 label {
	 font-size: 12px;
	 color: var(--bs-primary);
 }
 
 #root,
 #main-container {
	 height: 100%;
 }
 
 .sbold {
	 font-weight: 500 !important;
 }
 
 .btn-unstyled {
	 background: transparent;
	 border: 0;
	 padding: 0;
	 margin: 0;
 }
 
 input[type=number].no-arrows {
	 /* Chrome, Safari, Edge, Opera */
	 &::-webkit-outer-spin-button,
	 &::-webkit-inner-spin-button {
	   -webkit-appearance: none;
	   margin: 0;
	 }
 
	 /* Firefox */
	 & {
	   -moz-appearance: textfield;
	 }
 }
 
 .btn-light {
	 border: 1px solid rgb(206, 212, 218);
 
	 &:hover {
		 border-color: rgb(183 187 191) !important;
	 }
 }
 
 
 /**
  *	Colors
  */
 .bg-indigo {
	 background: var(--bs-indigo);
 }
 
 .text-indigo {
	 color: var(--bs-indigo);
 }
 
 .bg-pink {
	 background: var(--bs-pink);
 }
 
 .text-pink {
	 color: var(--bs-pink);
 }
 
 .bg-orange {
	 background: var(--bs-orange);
 }
 
 .text-orange {
	 color: var(--bs-orange);
 }
 
 
 
 /**
  * Tables
  */
 .table-responsive-carded {
	 border-radius: 5px;
 }
 
 .table-carded {
	 margin-bottom: 0;
	 background: white;
 }
 
 .table {
 
	 & > :not(:first-child) {
		 border-top-color: var(--bs-blue-light);
	 }
 
	 .btn-table {
		 transition: all .2s ease-in-out;
 
		 &:hover {
			 display: inline-block;
			 transform: scale(1.2);
		 }
	 }
 }
 
 .table-sortable {
 
	 thead {
 
		 tr {
 
			 th {
				 font-weight: 600;
 
				 &.sortable {
					 cursor: pointer;
					 white-space: nowrap;
 
					 &.active {
						 color: var(--bs-blue);
 
						 &::after {
							 display: inline-block;
							 font-size: inherit;
							 line-height: inherit;
							 margin-left: 5px;
						 }
 
						 &.asc {
							 &::after {
								 content: '↑';
							 }
						 }
 
						 &.desc {
							 &::after {
								 content: '↓';
							 }
						 }
					 }
				 }	
			 }
		 }
	 }
 
	 tbody {
 
		 tr {
 
			 &.details {
				 &:hover {
 
					 * {
						 --bs-table-accent-bg: inherit !important; // Prevent hover background for tablehover if tr is details container
					 }
				 }
			 }
 
			 &.has-details {
				 cursor: pointer;
			 }
		 }
	 }
 
 }
 
 
 